<style lang="scss" scoped xmlns="http://www.w3.org/1999/html"></style>

<template>
  <div class="consultingManagement">
    <ds-header title="企业用户审批列表"></ds-header>
      <a-form class="formModel" layout="inline">
        <a-form-item label="企业/团队名称：" class="d-flex">
          <a-input style="width: 200px;" v-model="search.enterprise_name" placeholder="请输入企业/团队名称" />
        </a-form-item>
        <a-form-item label="姓名/手机号：" class="d-flex">
          <a-input style="width: 200px;" v-model="search.name_or_phone" placeholder="请输入姓名/手机号" />
        </a-form-item>
        <a-form-item label="状态" class="d-flex">
          <a-select style="width: 200px;" default-value="请选择状态" v-model="search.apply_status"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
            <a-select-option :value="item.value" v-for="item in status" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="d-flex">
          <a-button class="m-20" style="margin-right: 10px;" type="primary" @click="searchList">
            <a-icon type="search" />
            查询
          </a-button>
          <a-button class="m-10" @click="reset"> 重置 </a-button>
        </a-form-item>
      </a-form>
      <div style="margin-top: 10px">
        <a-table :rowKey="(record, index) => record.menuId" :columns="table.columns" :data-source="table.data"
          :pagination="table.pagination" @change="pageChange" :scroll="{ x: 1500 }" bordered>
          <span slot="action" slot-scope="text, record">
            <a-button v-if="record.apply_status == 0" type="link" @click="examine(record)"
              :disabled="record.apply_status == 1">
              审批
            </a-button>
            <a-button v-if="record.apply_status == 1 || record.apply_status == 2" type="link"
              @click="lookDetail(record)">
              查看详情 
            </a-button>
          </span>
        </a-table>
      </div>
      <a-modal title="企业认证审批" v-if="visible" :visible="visible" @cancel="cancel" @ok="handleOk">
        <div>
          <h2 class="detail_title">申请信息</h2>
          <div class="detai_item">
            <p>姓名</p>
            <p>{{ detail.apply_name }}</p>
          </div>
          <div class="detai_item">
            <p>手机号</p>
            <p>{{ detail.apply_mobile }}</p>
          </div>
          <div class="detai_item">
            <p>企业/团队名称</p>
            <p>{{ detail.apply_company_name }}</p>
          </div>
          <div class="detai_item">
            <p>申请时间</p>
            <p>{{ moment(detail.apply_time).format("YYYY-MM-DD HH:mm:ss") }}</p>
          </div>
          <div class="detai_item">
            <p>申请备注</p>
            <p>{{ detail.apply_reason ? detail.apply_reason : '-' }}</p>
          </div>
          <h2 class="detail_title">审批信息</h2>
          <a-form-item label="审批是否通过" required>
            <a-radio-group v-model="apply_status">
              <a-radio :value="1">通过</a-radio>
              <a-radio :value="2">不通过</a-radio>
            </a-radio-group>
          </a-form-item>
          <div v-if="apply_status == 1">
            <p>所属部门</p>
            <a-cascader id="departName" style="width: 300px" v-model="department" :fieldNames="departmentField"
              :options="linkageDate" placeholder="请选择部门" @change="editDepartmentChange" />
          </div>
          <div v-else>
            <p>拒绝理由</p>
            <a-textarea :maxLength="20" v-model="reject_reason" placeholder="请输入驳回理由，不超过 20 个字" />
          </div>
        </div>
        <!-- <a-form-item
        label="审批理由："
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 16 }"
      >
        <a-textarea disabled v-model="text" placeholder="" />
        <span style="color:red;margin"
          >操作后不会通知企业/团队，谨慎操作！</span
        >
        <span style="color: red">审批通过后请在用户列表编辑相关信息！</span>
      </a-form-item>
      <div style="text-align: center">
        <a-button :disabled="handleCancelButDisabled" @click="handleCancel">审批不通过</a-button>
        <a-button :disabled="handleOkButDisabled" @click="handleOk" type="primary"> 审批通过 </a-button>
      </div> -->
      </a-modal>
      <!-- 审批详情 -->
      <a-modal title="企业认证审批详情" v-if="detailShow" :maskClosable='false' :visible="detailShow"
        :confirm-loading="confirmLoading" @ok="detailShow = false" @cancel="detailShow = false"
        :bodyStyle="{ height: '600px', overflowY: 'scroll' }" :centered="true">
        <div>
          <h2 class="detail_title">申请信息</h2>
          <div class="detai_item">
            <p>姓名</p>
            <p>{{ detail.apply_name }}</p>
          </div>
          <div class="detai_item">
            <p>手机号</p>
            <p>{{ detail.apply_mobile }}</p>
          </div>
          <div class="detai_item">
            <p>企业/团队名称</p>
            <p>{{ detail.apply_company_name }}</p>
          </div>
          <div class="detai_item">
            <p>申请时间</p>
            <p>{{ moment(detail.apply_time).format("YYYY-MM-DD HH:mm:ss") }}</p>
          </div>
          <div class="detai_item">
            <p>申请备注</p>
            <p>{{ detail.apply_reason ? detail.apply_reason : '-' }}</p>
          </div>
          <h2 class="detail_title">
            审批信息
          </h2>
          <div class="detai_item">
            <p>审批是否通过</p>
            <p>{{ detail.apply_status == 1 ? "是" : "否" }}</p>
          </div>
          <div class="detai_item" v-if="detail.apply_status == 1">
            <p>所属部门</p>
            <p>{{ detail.depart_ment_name ? detail.depart_ment_name : '-' }}</p>
          </div>
          <div class="detai_item" v-if="detail.apply_status != 1">
            <p>驳回理由</p>
            <p>{{ detail.reject_reason ? detail.reject_reason : '-' }}</p>
          </div>
          <div class="detai_item" v-if="detail.operator_name">
            <p>审批人</p>
            <p>{{ detail.operator_name }}</p>
          </div>
          <div class="detai_item" v-if="detail.operation_time">
            <p>审批时间</p>
            <p>{{ moment(detail.operation_time).format("YYYY-MM-DD HH:mm:ss") }}</p>
          </div>
        </div>
      </a-modal>

  </div>
</template>

<script type="text/babel">
/**
 * TODO fileOverview
 * --------------
 * @author xiaoF
 * @since 2021/6/15.
 */
import moment from "moment";
import {
  staffApplyList,
  verifystaff,
  staffentrPrise,
  getCompanyArchitecture
} from "@/api/staffApply/staffApply.js";
export default {
  name: "index",
  components: {},
  data() {
    return {
      handleCancelButDisabled: false,
      handleOkButDisabled: false,
      search: {
        enterprise_name: "",
        name_or_phone: "",
        apply_status: undefined,
      },
      visible: false,
      confirmLoading: false,
      detailShow: false,// 详情展示
      toExamine: {},
      text: "",
      table: {
        columns: [
          // {
          //   title: "账号",
          //   dataIndex: "apply_mobile",
          //   key: "apply_company_id",
          // },
          {
            title: "姓名",
            dataIndex: "apply_name",
            key: "apply_name",
            width:'200px',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "手机号",
            dataIndex: "apply_mobile",
            key: "apply_mobile",
            width:'200px',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "企业/团队名称",
            dataIndex: "apply_company_name",
            key: "apply_company_name",
            width: '400px',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "申请理由",
            dataIndex: "apply_reason",
            key: "apply_reason",
            width: '310px',
            customRender: (text) =>
              text ? text : "-",
          },

          {
            title: "申请时间",
            dataIndex: "apply_time",
            key: "apply_time",
            width:'200px',
            customRender: (text) =>
              text !== null ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-",
          },
          {
            title: "审批时间",
            dataIndex: "operation_time",
            key: "operation_time",
            width:'200px',
            customRender: (text, record) => {
              if (record.apply_status == 3) {
                return '-'
              } else {
                return text !== null ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"
              }
            }
          },
          {
            title: "状态",
            dataIndex: "apply_status_str",
            key: "apply_status_str",
            width: '100px',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "操作",
            key: "action",
            className: "action-ql-list",
            width: "200px",
            fixed: 'right',
            scopedSlots: { customRender: "action" },
          },
        ],
        data: [],
        pagination: {
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total, range) => {
            return `共${total}条`;
          },
        },
        height:''
      },
      // 审批状态
      status: [
        {
          value: "0",
          label: "待审批",
        },
        {
          value: "1",
          label: "已通过",
        },
        {
          value: "2",
          label: "已驳回",
        },
        {
          value: "3",
          label: "已过期",
        },
      ],
      detail: {},
      apply_status: 1,
      reject_reason: '',
      departmentId: '',
      // 联动的对应字段
      departmentField: {
        value: 'id',
        label: 'depart_name',
        children: 'items'
      },
      // 企业组织架构联动数据
      linkageDate: [],
      department: []
    };
  },
  created() {
    this.getEnterpriseName();
  },
  methods: {
    moment,
    // 审批理由详情
    async staffentrPrise(id) {
      const res = await staffentrPrise(id);
      this.detail = res.data;
      this.text = res.data.apply_reason;
    },
    cancel() {
      this.visible = false;
      this.reject_reason = '';
      this.apply_status = 1;
      this.departmentId = '';
      this.toExamine = {}
      this.department = []
    },
    // 审批弹框弹出
    examine(record) {
      this.visible = true;
      this.toExamine.apply_company_id = record.apply_company_id;
      this.toExamine.id = record.id;
      this.toExamine.apply_mobile = record.apply_mobile;
      this.toExamine.apply_name = record.apply_name;
      this.toExamine.join_source = 3;
      this.staffentrPrise(record.id);
      this.getCompanyArchitectureInfo(record.apply_company_id)
    },
    // 审批通过
    async handleOk() {
      this.toExamine.apply_status = this.apply_status;
      if (this.apply_status == 2) {
        this.toExamine.reject_reason = this.reject_reason;
      } else {
        this.toExamine.depart_ment_id = this.departmentId;
      }
      this.handleOkButDisabled = true
      const res = await verifystaff(this.toExamine);
      this.handleOkButDisabled = false
      if (res.data.code == 200) {
        this.$message.success(res.data.msg);
        this.cancel()
        this.getEnterpriseName();
      } else {
        this.cancel()
        this.getEnterpriseName();
        this.$message.error(res.data.msg);
      }
    },
    // 审批不通过
    async handleCancel() {
      this.toExamine.apply_status = 2;
      this.handleCancelButDisabled = true
      const res = await verifystaff(this.toExamine);
      this.handleCancelButDisabled = false
      if (res.data.code == 200) {
        this.$message.success("审批已拒绝");
        this.getEnterpriseName();
      } else {
        this.getEnterpriseName();
        this.$message.error(res.data.msg);
      }
      this.visible = false;
    },
    // 获取列表
    async getEnterpriseName() {
      try {
        const res = await staffApplyList({
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.search
        }
        );
        console.log(res);
        if (res.data.code == 200) {
          this.table.data = res.data.data.list;
          this.table.pagination.total = res.data.data.total;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 查询
    searchList() {
      this.table.pagination.current = 1;
      this.getEnterpriseName();
    },
    // 重置
    reset() {
      this.search.enterprise_name = "";
      this.search.name_or_phone = "";
      this.search.apply_status = undefined;
      this.table.pagination.current = 1;
      this.getEnterpriseName();
    },
    pageChange(pageOption) {
      this.table.pagination.current = pageOption.current;
      this.table.pagination.pageSize = pageOption.pageSize;
      this.getEnterpriseName();
    },
    // 产看详情
    async lookDetail(record) {
      await this.staffentrPrise(record.id)
      this.detailShow = true
    },
    // 获得公司组织架构
    getCompanyArchitectureInfo(id) {
      getCompanyArchitecture(id).then(res => {
        this.linkageDate = res.data.items
      })
    },

    editDepartmentChange(value, selectedOptions) {
      if (selectedOptions === undefined) {
        this.departmentId = ''
      } else {
        this.departmentId = value[value.length - 1]
      }
    },
  },
  mounted() {
    let handleResize = () => {
      this.height = window.innerHeight - 341 - 64 - 40
      let width = document.getElementsByClassName('consultingManagement')[0].clientWidth;
      if (width > 1890) {
        this.table.columns[this.table.columns.length - 1].fixed = '';
      } else {
        this.table.columns[this.table.columns.length - 1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
};
</script>
<style scoped lang="scss">
.detail_title {
  font-weight: 600
}

.detai_item {
  p {
    color: rgba(153, 153, 153, 1)
  }

  p+p {
    color: rgba(0, 0, 0, 0.85);
  }
}
::v-deep .ant-table-thead>tr>th {
  padding: 8px 16px;
}

::v-deep .ant-table-tbody>tr>td {
  padding: 8px 16px;
}
</style>
